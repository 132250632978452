@import 'scss/variables/index.scss';

.pd-footer {
  background: $primary-color;
  padding: $spacing-lg;
  .copyright {
    color: $pd-color-light-gray;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}
@import './media-query';
