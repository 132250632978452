@import 'scss/typography/index';
@import 'scss/variables/index.scss';

.pd-home {
  &--banner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: $pd-color-light-gray;
    .banner-container {
      width: 100%;
      background-color: $pd-color-gray;
      background-size: cover;
      background-position: center;
      height: 85vh;
    }
  }
}

@import './media-query';
