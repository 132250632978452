@import 'scss/variables/index';
@media only screen and (min-width: $desktop) {
  .pd-home {
    &--banner {
      .banner-container {
        height: 50vw;
      }
    }
  }
}
