@import 'scss/variables/index';

@media only screen and (min-width: $desktop) {
  .pd-home {
    .heading {
      text-align: center;
      .line {
        margin-bottom: 0px;
        height: 5px;
        border: none;
        background-color: $pd-color-gray;
        width: 13%;
      }
    }
  }
}
