@import 'scss/variables/index';

.heading {
  text-align: center;
  .line {
    margin-bottom: 0px;
    height: 5px;
    border: none;
    background-color: $pd-color-gray;
    width: 25%;
  }
}
@import './media_queries';
