@import 'scss/variables/index';

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--video {
      .video-container {
        .video {
          height: 504px;
        }
      }
    }
  }
}
