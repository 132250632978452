@import 'scss/typography/index';
@import 'scss/variables/index.scss';

.pd-home {
  &--video {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: $pd-color-light-gray;
    .video-container {
      width: 100%;
      .video {
        height: 343px;
        background-color: $pd-color-gray;
        background-size: cover;
        background-position: center;
        .play-icon {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          justify-content: center;
          position: absolute;
          width: 100%;

          i {
            color: $pd-color-white;
            font-size: 90px;
            opacity: 0.4;
          }
          &:active,
          &:hover {
            i {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@import './media-query';
