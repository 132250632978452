@font-face {
  font-family: 'Brandon_thin';
  src: url('./fonts/Brandon_thin.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon_light';
  src: url('./fonts/Brandon_light.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('./fonts/Brandon_reg.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon_med';
  src: url('./fonts/Brandon_med.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon_blk';
  src: url('./fonts/Brandon_blk.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon_Grotesque';
  src: url('./fonts/brandon-grotesque.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon_Grotesque_Italic';
  src: url('./fonts/brandon-grotesque-italic.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham_Rounded';
  src: url('./fonts/gothamrounded-bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham_Rounded_Medium';
  src: url('./fonts/gothamrounded-med.otf');
  font-weight: bold;
  font-style: normal;
}
