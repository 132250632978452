@import 'scss/variables/index';

.pd-home {
  &--small-square-tile {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $pd-color-light-gray;
    width: 100%;
    height: 44vw;
  }
}

@import './media_queries';
