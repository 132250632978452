@import 'scss/typography/index';
@import 'scss/variables/index.scss';

.pd-home {
  &--gallery {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: $pd-color-white;
    .tabbed-tile {
      padding-top: 44px;
      .tile-container {
        padding-top: 54px;
      }
      .ant-tabs-nav-container {
        font-size: 20px;
      }
      .ant-tabs-bar {
        margin: 0 auto;
        text-align: center;
        color: #000;
        border-bottom: none;
      }
      .ant-tabs-nav .ant-tabs-tab {
        padding-bottom: 12px;
        padding-top: 0px;
        width: 140px;
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        color: #000;
      }
      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #fdbc30;
      }
      .ant-tabs-ink-bar {
        background-color: #fdbc30;
        width: 140px !important;
      }
    }
  }
}

@import './media-query';
