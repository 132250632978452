@import 'scss/variables/index';

.pd-mobile-menu {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;

  .header {
    padding: $spacing-md 0px;
    box-shadow: 0 4px 2px -2px $pd-color-gray;

    .side-btn {
      cursor: pointer;
      position: absolute;
      right: 10px;
      padding: $spacing-xs 0px;
      z-index: 1;
      font-size: 20px;
      margin-top: $spacing-xs;
    }
    .title {
      color: $pd-color-darker-gray;
      .img-container {
        max-width: 150px;
        img {
          padding: $spacing-xs $spacing-md;
          max-width: 150px;
          width: 100%;
        }
      }
    }
  }

  .mobile-menu {
    padding: $spacing-xs $spacing-md;
    a {
      display: block;

      &:not(:last-of-type) {
        border-bottom: solid 1px $pd-color-gray;
      }
    }

    .menu {
      font-size: 16px;
      font-family: 'Gotham_Rounded_Medium', sans-serif;
      list-style: none;
      padding: $spacing-md 0px;
      color: black;

      &:hover {
        color: $pd-color-gray;
      }
    }
  }
}
