.pd-home {
  &--hiring-square {
    width: 100%;
    height: 91vw;
    .square {
      width: 90%;
      height: 90%;
      position: absolute;
    }
    .front {
      background-color: white;
      background-size: cover;
      background-position: center;
      bottom: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);
      right: 0;
    }
    .back {
      top: 0;
      left: 0;
      background-color: #dedede;
    }
  }
}

@import './media_queries';
