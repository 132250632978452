@import 'scss/variables/index.scss';

.pd-header {
  background: $pd-color-white;
  color: $pd-color-black;
  padding: $spacing-md 0px;

  a {
    &:hover {
      color: $pd-color-dark-gray;
    }
  }

  &-logo {
    padding: $spacing-xs $spacing-md;
    max-width: 150px;
    width: 100%;
  }

  &-right {
    text-align: right;
    .menu {
      color: $pd-color-black;
      font-size: 18px;
      padding-left: $spacing-lg;
      &:hover {
        color: $pd-color-dark-gray;
      }
    }
  }

  &-btn {
    border-color: $pd-color-black!important;
    color: $pd-color-black!important;
    float: right;

    &:hover, &:focus {
      color: $pd-color-dark-gray!important;
      border-color: $pd-color-dark-gray!important;
    }
  }

  .menu-icon {
    float: right;
    margin-right: 20%;
    font-size: 25px;
    padding-bottom: 2.5px;
  }
}
@import './media-query';
