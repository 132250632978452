@import 'scss/typography/index';
@import 'scss/variables/index.scss';

.pd-home {
  &--hiring {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    background-color: $pd-color-white;
    .hiring-container {
      padding-top: 54px;
      .hiring-text {
        padding-left: 0px;
        padding-top: 50px;
      }
    }
  }
}

@import './media-query';
