@import 'scss/variables/index';

.pd-video-player {
  align-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  &-overlay {
    position: absolute;
  }

  &-media {
    cursor: pointer;
    max-height: 80vh;
    max-width: 90vw;
  }

  &-close-btn {
    color: $pd-color-white;
    cursor: pointer;
    font-size: 35px;
    position: absolute;
    right: 60px;
    top: 25px;
  }
}

@import './media-query';
