@import 'scss/variables/index';

@media only screen and (min-width: $tablet) {
  .pd-home {
    &--small-square-tile {
      height: 28vw !important;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--small-square-tile {
      height: 24vw !important;
    }
  }
}
