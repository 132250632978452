@import 'scss/variables/index';

@media only screen and (min-width: $tablet) {
  .pd-home {
    &--hiring-square {
      max-height: 45vw !important;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--hiring-square {
      height: 37vw !important;
    }
  }
}
