.title {
  font-family: 'Gotham_Rounded', sans-serif;
  color: black;

  &-1 {
    font-size: 24px;
  }
  &-2 {
    font-size: 29px;
  }
  &-3 {
    font-size: 18px;
  }
  &-4 {
    font-size: 8px;
  }
}

.description {
  font-family: 'Brandon', sans-serif;
  font-size: 14px;
  line-height: 26px;
  color: black;
  &-italic {
    font-family: 'Brandon_Grotesque_Italic', sans-serif;
  }
  &-medium {
    font-family: 'Brandon_med', sans-serif;
  }
}

@import './media-query';
