@import 'scss/typography/index';
@import 'scss/variables/index.scss';

.pd-home {
  &--location {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: $pd-color-light-gray;
    .location-container {
      padding-top: 80px;
    }
    .mobile-location {
      padding-top: 54px;
      .ant-card-cover {
        height: 165px;
        overflow: hidden;
        object-fit: cover;
      }
      .ant-card-meta-description {
        font-weight: 500;
        font-family: 'Brandon_Grotesque_Italic', sans-serif;
      }
      .ant-card-meta-title {
        font-family: 'Gotham_Rounded', sans-serif;
        font-size: 20px;
      }
      .additional-info {
        margin-top: 1em;
        font-family: 'Brandon_med', sans-serif;
        .margin-half {
          margin-bottom: 0.25em;
        }
        .courier-logo {
          width: 100%;
        }
      }
    }
    .more-locations {
      .ReactCollapse--collapse {
        transition: height 500ms;
      }
      padding-top: 60px;
      .address {
        text-align: center;
      }
      .see-more-container {
        width: 100%;
        text-align: center;
        .see-more {
          color: $pd-color-dark-gray;
          cursor: pointer;
        }
        .see-more:hover {
          color: $pd-color-darker-gray;
        }
      }
    }
  }
}

@import './media-query';
