@import 'scss/variables/index';

@media only screen and (min-width: $tablet) {
  .pd-home {
    &--our-story {
      .textContainer {
        padding-left: 40px;
        padding-top: 0px;

        .description {
          padding-top: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--our-story {
      .textContainer {
        padding-left: 108px;
        padding-top: 0px;

        .description {
          padding-top: 30px;
        }
      }
    }
  }
}
