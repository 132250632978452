@import 'scss/variables/index';

@media only screen and (min-width: $tablet) {
  .pd-home {
    &--hiring {
      .hiring-container {
        .hiring-text {
          padding-left: 40px;
          padding-top: 0px;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--hiring {
      .hiring-container {
        .hiring-text {
          padding-left: 108px;
          padding-top: 0px;
        }
      }
    }
  }
}
