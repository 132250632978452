@import 'scss/variables/index';

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--gallery {
      .tabbed-tile {
        padding-top: 64px;
        .ant-tabs-nav .ant-tabs-tab {
          padding: 12px 0;
          width: 180px;
        }
        .ant-tabs-ink-bar {
          background-color: #fdbc30;
          width: 180px !important;
        }
        .tile-container {
          padding-top: 86px;
        }
      }
    }
  }
}
