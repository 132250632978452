@media only screen and (min-width: $desktop) {
  .pd-header {
    &-right {
      text-align: right;
      .menu {
        padding-left: $spacing-lg;
      }
    }
    &-logo {
      max-width: 200px;
      padding: $spacing-xs;
    }
  }
}
