@import 'scss/variables/index.scss';

.pd-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;

  .ant-spin {
    color: $primary-color;
  }
}
