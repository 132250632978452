@import 'scss/variables/index';

@media only screen and (min-width: $tablet) {
  .pd-home {
    &--location {
      .mobile-location {
        .ant-card-cover {
          height: 350px;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .pd-home {
    &--location {
      .more-locations {
        padding-top: 6vw;
      }
    }
  }
}
