@import 'scss/utils/index';
@import 'scss/variables/index';
@import 'scss/section/index';
@import 'scss/typography/index';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Bebas Neue', cursive;
  font-size: 16px;
}

a {
  color: inherit;

  &:hover {
    color: $primary-color;
  }
}
