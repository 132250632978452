@import 'scss/variables/index';
.pd-home {
  &--desktop-location {
    width: 100%;
    height: 27vw;
    display: flex;
    flex-direction: row;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    .overlay {
      background-color: white;
      width: 400px;
      height: 100%;
      display: flex;
      padding: 0px 37px;
      flex-direction: column;
      justify-content: center;

      .margin-half { margin-bottom: 0.25em; }

      .courier-logo { width: 100% }
    }
    .images {
      background-size: cover;
      background-color: $pd-color-gray;
      width: 100%;
      height: 100%;
    }
  }
}
