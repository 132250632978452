@import 'scss/typography/index';
@import 'scss/variables/index.scss';

.pd-home {
  &--our-story {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: $pd-color-white;

    .textContainer {
      height: 100%;
      padding-top: 40px;
      .description {
        padding-top: 12px;
      }
    }
  }
}

@import './media-query';
